import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {
    Outlet,
    useFetcher,
    useLoaderData,
    useLocation,
    useNavigate,
    useSearchParams,
    useTransition
} from "@remix-run/react";
import type {LoaderFunction} from "@remix-run/node";
import {json} from "@remix-run/node";
import clsx from "clsx";
import {
    CategoryMenu,
    CategoryMenuMobile,
    OfferTile,
    PostPreview,
    WithBackgroundGradient
} from "@bettorsignals/ui";
import type {Bookmaker, Offer, Post} from "@bettorsignals/api";
import {Status, CategoryEnum, Collection, isBookmaker, isOffer, isPost} from "@bettorsignals/api";
import {CATEGORIES} from "~/constants/Categories.dict";
import {LinkTo} from "~/utils/LinkTo";
import {$api} from "~/api";
import {countReadingTimeInMinutes} from "~/utils/countReadingTimeInMinutes";

interface LoaderData {
    offers: Offer[];
    bookmakers: Bookmaker[];
    blogPosts: Array<Post & {readingTimeInMinutes: number; href: string}>;
}

export const loader: LoaderFunction = async ({request: {headers}}) => {
    const linkTo = new LinkTo(process.env.API_URL!);

    // These headers are provided by CloudFront, so will be nullish on localhost
    const userCountry = headers.get("CloudFront-Viewer-Country");
    const userRegion = headers.get("CloudFront-Viewer-Country-Region");

    const bookmakersRequest = $api
        .items(Collection.Bookmakers)
        .readByQuery({
            fields: ["*", "regions.region.country.code", "regions.region.regionCode"]
        })
        .catch((error) => {
            console.error(error);
            return null;
        });

    const offersRequest = $api
        .items(Collection.Offers)
        .readByQuery({
            fields: ["*", "regions.region.country.code", "regions.region.regionCode"]
        })
        .catch((error) => {
            console.error(error);
            return null;
        });

    const blogPostsRequest = $api
        .items(Collection.Posts)
        .readByQuery({
            filter: {
                status: {
                    _eq: Status.Published
                }
            },
            sort: ["-datePublished"],
            limit: 5
        })
        .catch((error) => {
            console.error(error);
            return null;
        });

    const [bookmakersResponse, offersResponse, blogPostsResponse] = await Promise.all([
        bookmakersRequest,
        offersRequest,
        blogPostsRequest
    ]);

    const geotargetedBookmakers = (bookmakersResponse?.data ?? []).filter(
        (bookmaker): bookmaker is Bookmaker => {
            const isValidBookmaker = isBookmaker(bookmaker);

            const isBookmakerWhitelisted = bookmaker.regions.some(
                (junction: any) =>
                    (junction.region.country.code === "*" ||
                        junction.region.country.code === userCountry) &&
                    (junction.region.regionCode === "*" ||
                        junction.region.regionCode === userRegion)
            );

            return isValidBookmaker && isBookmakerWhitelisted;
        }
    );

    const bookmakersDict: {[bookmakerId in string]: Bookmaker} = Object.fromEntries(
        geotargetedBookmakers.map((bookmaker) => [
            bookmaker.id,
            {
                ...bookmaker,
                url: LinkTo.bookmaker(bookmaker.id),
                logoRectangle: linkTo.asset(bookmaker.logoRectangle, {
                    seoFriendlyName: `${bookmaker.name}-logo`
                }),
                logoSquare: linkTo.asset(bookmaker.logoSquare, {
                    seoFriendlyName: `${bookmaker.name}-logo`
                })
            }
        ])
    );

    const geotargetedOffers = (offersResponse?.data ?? []).filter(
        (offer): offer is LoaderData["offers"][number] => {
            const isValidOffer = isOffer(offer);

            const isOfferWhitelisted = offer.regions.some(
                (junction: any) =>
                    (junction.region.country.code === "*" ||
                        junction.region.country.code === userCountry) &&
                    (junction.region.regionCode === "*" ||
                        junction.region.regionCode === userRegion)
            );

            return isValidOffer && isOfferWhitelisted;
        }
    );

    const offers: LoaderData["offers"] = geotargetedOffers.map((offer) => ({
        ...offer,
        url: LinkTo.offer(offer.id),
        logoRectangle: offer.logoRectangle
            ? linkTo.asset(offer.logoRectangle, {
                  seoFriendlyName: "Offer-logo"
              })
            : null,
        backgroundImage: offer.backgroundImage
            ? linkTo.asset(offer.backgroundImage, {
                  seoFriendlyName: "Offer-background"
              })
            : null
    }));

    const posts: LoaderData["blogPosts"] =
        blogPostsResponse?.data
            ?.filter(isPost)
            .slice(0, offers.length === 0 ? 5 : 3) //Show more blog posts if there are no offers for the user
            .map((post) => ({
                ...post,
                cover: linkTo.asset(post.cover!, {height: 192}),
                datePublished: new Date(post.datePublished).toLocaleString("default", {
                    month: "short",
                    day: "2-digit"
                }),
                href: LinkTo.blogPostPage(post.slug),
                readingTimeInMinutes: countReadingTimeInMinutes(post.content)
            })) ?? [];

    return json<LoaderData>({
        offers,
        bookmakers: Object.values(bookmakersDict),
        blogPosts: posts
    });
};

export type WithOffersOutletContext = {
    selectedCategory: CategoryEnum;
} & Pick<LoaderData, "offers" | "bookmakers">;

//TODO implement categories count - https://app.clickup.com/t/2u0qkuu
export default function WithOffersRoute() {
    const [searchParameters, setSearchParameters] = useSearchParams();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {offers, bookmakers, blogPosts} = useLoaderData<LoaderData>();
    const transition = useTransition();
    const fetcher = useFetcher();

    const [selectedCategory, setSelectedCategory] = useState<CategoryEnum>(
        Object.values(CategoryEnum).includes(searchParameters.get("category") as CategoryEnum)
            ? (searchParameters.get("category") as CategoryEnum)
            : CategoryEnum.BestSignals
    );

    useEffect(() => {
        //Set to default if some other effect wipes out the search parameters (i.e. navigating to home page)
        if (
            transition.state === "loading" &&
            !transition.location?.search &&
            transition.location?.pathname === "/"
        ) {
            setSelectedCategory(CategoryEnum.BestSignals);
        }
    }, [transition.location?.pathname, transition.location?.search, transition.state]);

    const handleOnCategoryChange = useCallback(
        (category) => {
            const categoryUrl = `/?category=${category}`;
            if (pathname !== "/") {
                navigate(categoryUrl);
            } else {
                setSearchParameters({category});
            }
            setSelectedCategory(category);
            fetcher.load(categoryUrl);
        },
        [setSearchParameters, fetcher, navigate, pathname]
    );

    const context: WithOffersOutletContext = useMemo(
        () => ({selectedCategory, offers, bookmakers}),
        [selectedCategory, offers, bookmakers]
    );

    const shouldRenderCategoryMenu = useMemo(() => {
        return pathname === "/" || pathname.includes("signal/");
    }, [pathname]);

    return (
        <WithBackgroundGradient>
            <div className="relative mx-auto mt-[19px] grid h-full w-screen max-w-bs grid-cols-1 grid-rows-1 gap-7 overflow-hidden px-4 pb-16 md:mt-[26px] md:grid-cols-12 xl:px-0">
                {shouldRenderCategoryMenu && (
                    <div className="hidden md:col-span-3 lg:block">
                        <div className="sticky top-16">
                            <CategoryMenu
                                categories={CATEGORIES}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={handleOnCategoryChange}
                            />
                        </div>
                    </div>
                )}

                <main
                    className={clsx(
                        "col-span-1",
                        shouldRenderCategoryMenu ? "md:col-span-6" : "md:col-span-9"
                    )}
                >
                    <div className="flex min-h-[320px] flex-col gap-7">
                        {shouldRenderCategoryMenu && (
                            <CategoryMenuMobile
                                categories={CATEGORIES}
                                selectedCategory={selectedCategory}
                                setSelectedCategory={handleOnCategoryChange}
                            />
                        )}

                        <Outlet context={context} />
                    </div>
                </main>

                <div className="h-full w-full md:col-span-3">
                    <div className="flex flex-col gap-10">
                        {offers.length > 0 && (
                            <div className="hidden flex-col items-start justify-center gap-7 md:flex">
                                <h2 className="pb-1 text-xl font-bold text-gray-900 lg:text-2xl">
                                    Free Bets &amp; Offers
                                </h2>
                                <div className="flex w-full flex-col gap-7">
                                    {offers.map((offer) => (
                                        <Fragment key={offer.id}>
                                            <OfferTile {...offer} />
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col items-start justify-center gap-7">
                            <h2 className="pb-1 text-xl font-bold text-gray-900 lg:text-2xl">
                                From the Blog
                            </h2>
                            <div className="flex w-full flex-col gap-7">
                                {blogPosts.map((post) => (
                                    <Fragment key={post.id}>
                                        <PostPreview
                                            title={post.title}
                                            date={post.datePublished}
                                            coverImageUrl={post.cover!}
                                            href={post.href}
                                            readingTimeInMinutes={post.readingTimeInMinutes}
                                        />
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WithBackgroundGradient>
    );
}
